<template>
    <div class="wrap">
        <div class="page-main">
            <div class="form">
                <el-form
                    :rules="rules"
                    ref="form"
                    :model="form"
                    label-position="left"
                    label-width="160px"
                >
                    <el-form-item label="您的身份" prop="style" class="one">
                        <el-radio-group v-model="form.style">
                            <el-radio  :label="'1'" style="margin-right:30px">个人</el-radio >
                            <el-radio  :label="'0'">企业</el-radio >
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="企业名称" prop="enterpriseName" v-if="form.style == '0'">
                        <el-input placeholder="请输入企业名称" v-model="form.enterpriseName">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="联系人姓名" prop="name">
                        <el-input placeholder="请输入联系人姓名" v-model="form.name">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="联系人手机号" prop="phone">
                        <el-input placeholder="请输入联系人手机号" v-model="form.phone">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="滑块验证" prop="verify2">
                        <el-input
                            placeholder="请输入联系人手机号"
                            v-model="form.verify2"
                            v-show="false"
                        >
                        </el-input>
                        <Verification @verification="verification" ref="Verification"/>
                    </el-form-item>
                    <div class="yzm-item">
                        <el-form-item
                            label="短信验证码"                       
                            prop="captchaCode"
                        >
                            <el-input
                                v-model="form.captchaCode"
                                placeholder="短信验证码"
                            ></el-input>
                        </el-form-item>
                        <span
                            :disabled="disabled"
                            @click="getVer"
                            >{{ yzmtext }}</span
                        >
                    </div>
                    <el-form-item label="使用量" prop="num">
                        <el-input placeholder="使用量" v-model="form.num">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="需求描述" prop="description">
                        <el-input type="textarea" placeholder="请输入需求描述（不多于200字）" v-model="form.description" maxlength="200" show-word-limit :rows="7" @input="fontNumber">
                        </el-input>
                        <!-- <span style="float:right;margin-bottom:-34px">{{ fontNum }}/200</span> -->
                    </el-form-item>
                </el-form>
                <div class="btn">
                    <el-button type="primary" :loading="btnLoading" @click="submit">提交</el-button>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="successDiaBox" width="450px" class="myDialogNormal downDiaBox" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="dialog_cnt">
                <div class="icon"><i class="el-icon-success"></i></div>
                <h3>提交成功!</h3>
                <p>
                    请保持手机畅通，很快，我们的专业顾问会与您联系。
                </p>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button class="copybtn" type="primary" @click="know">好的</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Verification from "@/components/Verification/dockingV";
var interval=null;
import {sendSmsCode,sendDingDing2} from "@/api/tsaService";
export default {
    data () {
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号"));
            } else {
                if (!reg.test(value)) {
                    callback(new Error("请输入正确手机号"));
                } else {
                    callback();
                }
            }
        };
        let verifyPass = (rule, value, callback) => {
            if (!this.verify) {
                callback(new Error("请滑块验证"));
            } else {
                callback();
            }
        };
        let numPass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入使用量"));
            } else {
                if (!/^[1-9]\d*$/.test(value)) {
                    callback(new Error("请输入正整数"));
                } else {
                    callback();
                }
            }
            
        };
        return {
            successDiaBox:false,
            form: {
                style:'1',
                enterpriseName:"",
                phone: "",
                verify2: "",
                captchaCode: "",
                description: "",
                name: "",
                num:''
            },
            rules: {
                style: [
                    {
                        required: true,
                        message: "请选择身份",
                        trigger: "change"
                    }
                ],
                enterpriseName: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur"
                    }
                ],
                name: [
                    {
                        required: true,
                        message: "请输入联系人姓名",
                        trigger: "blur"
                    }
                ],
                phone: [
                    {
                        required: true,
                        trigger: "blur",
                        validator: validatePass
                    }
                ],
                num:[
                    {
                        required: true,
                        trigger: "blur",
                        validator: numPass
                    },
                ],
                description: [
                    {
                        required: true,
                        message: "请输入需求描述",
                        trigger: "blur"
                    }
                ],
                captchaCode: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "change"
                    }
                ],
                verify2: [
                    {
                        required: true,
                        trigger: "change",
                        validator: verifyPass
                    }
                ]
            },
            checkIndex: "",
            applyId: "1051649",
            yzmtext: "获取验证码",
            verify:false,
            currentTime: 60,
            disabled: false,
            fontNum:0,
            btnLoading:false,
        }
    },
    components: {Verification},
    computed: {},
    created() {},
    mounted() {},
    methods: {
        fontNumber(e) {
            this.fontNum = e.length;
        },
        async verification(data) {
            let that = this;
            if (data) {
                this.verify=data.verify;
                this.$refs["form"].clearValidate(["verify2"]);
                let { phone } = that.form;
                if (!phone) {
                    that.$message.warning("手机号不能为空");
                    that.verify=false;
                    that.$refs.Verification.resetNvc();
                    return false;
                }
                let reg = /^1[3456789]\d{9}$/;
                if (!reg.test(phone)) {
                    that.$message.warning("手机号格式不正确");
                    that.verify=false;
                    that.$refs.Verification.resetNvc();
                    return false;
                }
                that.getCode();
                let res = await sendSmsCode({
                    nvc: data.nvc,
                    phone: that.form.phone
                });
                if (res.code === 200) {
                    that.$message.success(res.message);
                    that.verify = true;
                    that.form.verify2 = "213";
                    that.$refs["form"].clearValidate(["verify2"]);
                } else {
                    that.$message.warning(res.data.message);
                    that.$refs.Verification.resetNvc();
                    clearInterval(interval);
                    that.yzmtext = "获取验证码";
                    that.disabled = false;
                    that.verify = false;
                }
            }
        },
        getVer() {
            let {phone} = this.form;
            let that = this;
            if (!phone) {
                that.$message.warning("手机号不能为空");
                return false;
            }
            let reg = /^1[3456789]\d{9}$/;
            if (!reg.test(phone)) {
                that.$message.warning("手机号格式不正确");
                return false;
            }
            if (!this.verify) {
                that.$message.warning("请先进行滑块验证");
                return false;
            }
            this.$refs.Verification.alicode();
        },
        getCode() {
            var that = this;
            var currentTime = that.currentTime;
            that.disabled = true;
            interval = setInterval(function() {
                currentTime--;
                that.yzmtext = "(" + currentTime + "s)重新获取";
                if (currentTime <= 0) {
                clearInterval(interval);
                that.currentTime = 60;
                that.yzmtext = "获取验证码";
                that.disabled = false;
                }
            }, 1000);
        },
        submit(e) {
            e.preventDefault();
            let that = this;
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.btnLoading = true;
                    let {phone, enterpriseName, name, captchaCode, description, num, style} = that.form;
                    let obj={
                        phone,
                        applyId: this.applyId,
                        enterpriseName,
                        name,
                        style,
                        captchaCode,
                        description,
                        from: 0,
                        num
                    }
                    try{
                        let res = await sendDingDing2(obj);
                        if (res.code === 200) {
                            that.form = {
                                style:'1',
                                name: "",
                                phone: "",
                                enterpriseName: "",
                                verify2: "",
                                captchaCode: "",
                                description: '',
                                num:''
                            };
                            that.$refs["form"].resetFields();
                            that.successDiaBox=true;
                        } else {
                            this.$message.warning(res.data.message);
                        }
                    }catch (error) {
                        this.$message.warning(error.message);
                    }
                    that.btnLoading = false;
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        know(){
            this.resetForm();
            this.$refs["form"].resetFields();
            this.$refs.Verification.resetNvc();
            this.successDiaBox=false;
        },
        //置空钉钉推送相关字段
        resetForm(){
            this.monitorOne=false;
            this.visibleOne = false;
            this.visibleSec = false;
            this.visibleThird = false;
            this.disabled = false;
            this.yzmtext = "获取验证码";
            this.currentTime=60;
            this.verify = false;
            this.checkIndex = "";
            this.applyId = "1051649";
            clearInterval(interval);
            this.form.enterpriseName = "";
            this.form.captchaCode = "";
        },
    },
}

</script>
<style lang='less' scoped>
.form{
    width: 660px;
    margin:0 auto;
    padding-top:50px;
    padding-bottom:20px;
    .btn{
        .el-button{
            width: 120px;
            height: 40px;
            text-align: center;
            cursor: pointer;
            display: block;
            color: #fff;
            background: #6288fb;
            margin:30px auto 0;
            border: 1px solid #6288fb;
        }
    }
    .yzm-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
        .el-form-item {
            width: 500px;
            margin-bottom: 0px;
        }
        span {
            width: 148px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            background: #fff;
            color: #3f7df7;
            cursor: pointer;
            border: 1px solid #3f7df7;
            font-size: 14px;
            border-radius: 3px;
            &:hover {
                background: #3f7df7;
                color: #fff;
            }
            &[disabled] {
                color: #fff;
                background: #bcbcbc;
                pointer-events: none;
                cursor: inherit;
                border-color: #bcbcbc;
            }
        }
    }
}
/deep/.downDiaBox{
    /deep/.el-dialog{ 
        height: auto;
    }
    .dialog_cnt{
        text-align: center;
        .icon {
            display: flex;
            justify-content: center;
            color: #32c016;
            text-align: center;
            font-size: 50px;
            margin-bottom: 19px;
        }
        h3{
            font-size: 16px;
            text-align:center;
        }
        .alignLeft{
            text-align:left;
        }
        .red{
            color: red;
        }
        .blue{
            color: #6288fb;
        }
    }
    .dialog_cnt_en{
        text-align: left;
        h3{
            text-align:left;
        }
    }
    .el-dialog__footer{
        text-align: center;
    }
    
}
</style>